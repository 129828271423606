import {
  useState,
  useEffect,
  HTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
} from 'react';
import cn from 'classnames';

import styles from './header.module.css';
import Navigation, { appearanceType, Menu } from './navigation';
import Logo, { type } from './logo';
import { Button, Container } from '.';

export interface HeaderProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  appearance?: 'contained' | 'transparent';
  appearanceLogo?: type;
  appearanceNav?: appearanceType;
  children?: ReactNode;
  menu: Menu[];
}

export default function Header({
  children,
  appearance,
  className,
  appearanceLogo,
  appearanceNav,
  menu,
  ...props
}: HeaderProps) {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(false);

  const handleClickMenu = () => {
    setVisibleMenu(false);
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      if (window.matchMedia('(max-width: 1024px)').matches) {
        setMobile(true);
      } else {
        setMobile(false);
        setVisibleMenu(false);
      }
    });
  }

  useEffect(() => {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      setMobile(true);
    }
  }, []);

  return (
    <header
      className={cn(className, styles.header, {
        [styles.contained]: appearance === 'contained',
        [styles.transparent]: appearance === 'transparent',
        [styles.header__menu_active]: visibleMenu,
      })}
      {...props}
    >
      <Container
        variant={appearanceNav === 'nft' ? 'container_medium' : 'container'}
      >
        <div className={styles.header_inner}>
          <Logo
            onClick={handleClickMenu}
            className={styles.header_logo}
            appearance={
              (visibleMenu && 'white') ||
              (mobile && appearanceLogo === 'fitkonnekt' && 'fitkonnekt') ||
              (mobile && appearanceLogo === 'keto' && 'keto') ||
              (mobile && appearanceLogo === 'healthCase' && 'healthCase') ||
              (mobile && appearanceLogo === 'nft' && 'nft') ||
              (mobile && appearanceLogo !== 'letslink' && 'primary') ||
              appearanceLogo
            }
          />
          <div
            className={cn(styles.wrapper, {
              [styles.active]: visibleMenu,
              [styles.wrapper_letslink]: appearanceNav === 'letslink',
              [styles.wrapper_fitkonnekt]: appearanceNav === 'fitkonnekt',
              [styles.wrapper_healthCase]: appearanceNav === 'healthCase',
              [styles.wrapper_healthCase]: appearanceNav === 'nft',
              [styles.wrapper_keto]: appearanceNav === 'keto',
            })}
          >
            <Navigation
              className={styles.header_nav}
              appearance={(visibleMenu && 'white') || appearanceNav}
              menu={menu}
              onHandleClick={handleClickMenu}
            />
            <div className={styles.buttons}>
              <Button
                tag="a"
                href="/contact"
                appearance={
                  (mobile && appearanceNav === 'fitkonnekt' && 'fitkonnekt') ||
                  (mobile && appearanceNav === 'keto' && 'keto') ||
                  (mobile && appearanceNav !== 'letslink' && 'ghost') ||
                  (appearanceNav === 'letslink' && appearanceNav) ||
                  (appearanceNav === 'healthCase' && appearanceNav) ||
                  (appearanceNav === 'fitkonnekt' && appearanceNav) ||
                  (appearanceNav === 'keto' && appearanceNav) ||
                  (appearanceNav === 'nft' && appearanceNav) ||
                  'primary'
                }
                onClick={handleClickMenu}
              >
                Contact us
              </Button>
            </div>
          </div>
          <div
            aria-hidden="true"
            onClick={() => setVisibleMenu((prev) => !prev)}
            className={cn(styles.burger, {
              [styles.burger_letslink]: appearanceNav === 'letslink',
              [styles.burger_fitkonnekt]: appearanceNav === 'fitkonnekt',
              [styles.burger_keto]: appearanceNav === 'keto',
              [styles.burger_healthCase]: appearanceNav === 'healthCase',
              [styles.burger_healthCase]: appearanceNav === 'nft',
            })}
          />
        </div>
      </Container>
    </header>
  );
}
