import { ServiceCardBlog } from '.';
import services from '../utils/services';
import styles from './service-card-list.module.css';

export default function ServiceCardList() {
  return (
    <ul className={styles.list}>
      {services.map(({ Icon, title, id }) => (
        <ServiceCardBlog title={title} key={id}>
          <Icon />
        </ServiceCardBlog>
      ))}
    </ul>
  );
}
