import {
  ForwardedRef,
  forwardRef,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
} from 'react';
import cn from 'classnames';
import styles from './textarea.module.css';
import { Error } from '.';

export interface TextareaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  error?: boolean;
  message?: string;
}

function Textarea(
  { label, error, className, message, ...props }: TextareaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element {
  return (
    <div className={cn(styles.wrapper, className)}>
      {label && (
        <label htmlFor="textarea" className={styles.label}>
          {label}
        </label>
      )}
      <textarea
        className={cn(styles.textarea, {
          [styles.error]: error,
        })}
        id="textarea"
        ref={ref}
        {...props}
      />
      {error && <Error>{message}</Error>}
    </div>
  );
}

export default forwardRef(Textarea);
