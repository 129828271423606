import React from 'react';
import { Container } from '../../components';
import styles from './awards.module.css';

export interface AwardsProps {
  title: string;
  leadText: string;
  leadImage: string;
  list: {
    image: string;
    title: string;
    text: string;
  }[];
}

export default function Awards({
  leadImage,
  leadText,
  list,
  title,
}: AwardsProps) {
  return (
    <section className={styles.awards}>
      <Container>
        <h1 className={styles.awards_title}>{title}</h1>
        <div className={styles.awards_inner}>
          <div className={styles.lead}>
            <div className={styles.lead_text}>{leadText}</div>
            <div className={styles.lead_image}>
              <img src={leadImage} alt="lead rate" />
            </div>
          </div>
          <ul className={styles.awards_list}>
            {list.map(({ image, text, title: itemTitle }, index) => (
              <li className={styles.awards_item} key={index}>
                <div className={styles.awards_itemImage}>
                  <img src={image} alt={itemTitle} />
                </div>
                <h3 className={styles.awards_itemTitle}>{itemTitle}</h3>
                <p className={styles.awards_itemText}>{text}</p>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  );
}
