import React, { useState } from 'react';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './form-contact-letslink.module.css';
import { useModal } from '../hooks';
import { IFormContact } from './form-contact';
import { subscribeSchema } from '../validation/submit.schema';
import { Feedback, Htag, HtagVariant, Paragraph } from '.';
import { sendMessage } from '../services/contact.service';
import TextMask from '../pages/linkapp/text-mask';
import TextField from '../pages/linkapp/text-field';

export default function FormContactLetslink() {
  const { open, setContent } = useModal();
  const [disabled, setDisabled] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormContact>({
    mode: 'onBlur',
    resolver: yupResolver(subscribeSchema),
  });

  const onSubmit = async (data: IFormContact) => {
    setDisabled(true);
    const response = await sendMessage(data);

    if (String(response.status).startsWith('2')) {
      setDisabled(false);
      setContent(<Feedback />);
      open();
      reset();
    }
  };

  return (
    <>
      <div className={styles.form_box}>
        <Htag tag="h3" className={styles.form_title} variant={HtagVariant.h3}>
          got a project in mind?
        </Htag>
        <Paragraph className={styles.form_subtitle}>
          Leave us a message and we will reach out to you
        </Paragraph>
      </div>
      <div className={styles.form_inner}>
        <form
          className={cn(styles.form_start, {
            [styles.disabled]: disabled,
          })}
        >
          <TextField
            className={styles.form_input}
            helper="Name"
            error={!!errors?.name}
            message={errors?.name?.message}
            placeholder="Your Name"
            {...register('name')}
          />

          <TextField
            className={styles.form_input}
            {...register('email')}
            error={!!errors?.email}
            helper="Email"
            message={errors?.email?.message}
            placeholder="Your Email"
          />

          <TextMask
            className={styles.form_input}
            label="Phone"
            placeholder="Your number"
            {...register('phone')}
            mask="+9999999999999"
          />

          <TextField
            className={styles.form_input}
            {...register('message')}
            helper="Message"
            placeholder="Text Message"
            error={!!errors?.message}
            message={errors?.message?.message}
          />
          <button
            className={styles.button}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Send
          </button>
        </form>
        <div className={styles.form_end}>
          <Image
            src="/letslink/contact/brain.png"
            alt="brain"
            width={373}
            height={408}
          />
        </div>
      </div>
    </>
  );
}
