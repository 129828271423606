import axios from 'axios';
import { IFormContact } from '../components/form-contact';

export const sendMessage = async (data: IFormContact) => {
  axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKEND_URL;

  const response = await axios.post(`/contact-form`, {
    ...data,
    captcha: '$2y$12$2psY/hTBog6iMrdOEVzAq.c6hqWwCoW1Gv3hvhqA7bcSZDf.WT1a6',
  });
  return response;
};
