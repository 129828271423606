import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Button, Htag, HtagAlign, HtagVariant, Paragraph } from '.';
import styles from './feedback.module.css';

export interface FeedbackProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export default function Feedback({ ...props }: FeedbackProps) {
  return (
    <div {...props}>
      <Htag
        tag="h3"
        align={HtagAlign.center}
        className={styles.title}
        variant={HtagVariant.h3}
      >
        Thank you!
      </Htag>
      <Paragraph align="center" className={styles.text}>
        We received your application and will contact you very soon!
      </Paragraph>
      <div className={styles.button}>
        <Button data-type="denied" appearance="primary">
          Close
        </Button>
      </div>
    </div>
  );
}
