import React, { HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './works-card-list.module.css';

export interface WorksCardListProps
  extends React.DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: ReactNode;
}

export default function WorksCardList({
  children,
  className,
}: WorksCardListProps) {
  return <ul className={cn(styles.list, className)}>{children}</ul>;
}
