import avatar_cruisbe from '../public/img/avatar_cruisbe.webp';
import partner from '../public/img/partner.webp';
import avatar_mobz from '../public/img/avatar_mobz.webp';
import bestflix from '../public/img/bestflix.webp';
import mel from '../public/img/mel.webp';

const staticComments = [
  {
    _id: 1,
    img: mel,
    text: "The team has been working hard to fully turned the client's vision into life. Mavinx also communicates well despite language barriers through Slack, WhatsApp, and Zoom. Furthermore, their honesty and patience are the highlights of this partnership.",
    name: 'Melanie Seers ',
    company: 'Co-Owner of the Hera app',
  },
  {
    _id: 2,
    img: avatar_cruisbe,
    text: "They really like to satisfy the client as much as possible ... I'm a pretty picky IT engineer, so they  needed some time to get to know what I wanted, but we were successful. And they will work on the comments and update the app till the customer is completely satisfied.",
    name: 'Marina Shumaieva',
    company: 'Co-Founder of the CruiseBe company',
  },
  {
    _id: 3,
    img: partner,
    text: 'It was perfect. Communications, scheduling was fast and in time. We set up calls, contacting in advance and always did everything in time or discussed the  changes in advance. They know their job. I got a lot of advice from them. They stayed within budget.',
    name: 'Ievgeniia Nasinyk',
    company: 'Co-Founder of BalloonPoster project',
  },
  {
    _id: 4,
    img: avatar_mobz,
    text: 'Mavinx successfully launched the apps, which resulted in numerous publisher contracts being signed. It generated an impressive content base and more than 50,000 users. The engagement was streamlined and stress-free; the team was on time and communicative.',
    name: 'Aleksandr Eisbrecher',
    company: 'Director and Founder of Mobiz',
  },
  {
    _id: 5,
    img: bestflix,
    text: 'I have been working with Ruslan and his team since 2014. During this period of time, we jointly released several mobile projects. I hope to continue our alliance with this reliable and responsive team of professionals for many years.',
    name: 'Igor Pogoraev',
    company: 'Founder of Bestflix',
  },
];

export default staticComments;
