import Image from 'next/image';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useRef } from 'react';
import styles from './post-section.module.css';
import { ISubtitle } from '../pages/blog/blog.types';
import { Htag, HtagVariant } from '.';

export interface SubtitleProps extends ISubtitle {
  index: number;
  setIndex: (index: number) => void;
}

export default function PostSection({
  setIndex,
  index,
  id,
  subtitle,
  text,
  image,
}: SubtitleProps) {
  const section = useRef<HTMLDivElement | null>(null);

  const paragraphs = text.split(/[\r\n]+/g);

  useScrollPosition(() => {
    let position = null;

    if (section && section.current) {
      position = section.current.getBoundingClientRect();
    }

    if (position && position.top <= 1 && position.top + position.height >= 0) {
      setIndex(index + 1);
    }
  });

  return (
    <section ref={section} id={`section${id}`} className={styles.section}>
      <Htag tag="h2" className={styles.title} variant={HtagVariant.h4}>
        {subtitle}
      </Htag>
      {paragraphs.map((paragraph, i) => (
        <p
          key={i}
          dangerouslySetInnerHTML={{
            __html: paragraph.trim(),
          }}
          className={styles.text}
        />
      ))}
      <Image
        unoptimized
        alt={subtitle}
        className={styles.img}
        width={1352}
        height={832}
        src={image}
      />
    </section>
  );
}
