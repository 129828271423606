import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './components-delivered.module.css';
import { Htag, HtagVariant } from '.';

export interface IComponentDelivered {
  id: number;
  Icon: FunctionComponent;
  text: string;
}

export interface ComponentsDeliveredProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  appearance?: 'two' | 'four';
  data: IComponentDelivered[];
}

export default function ComponentsDelivered({
  title,
  data,
  appearance = 'two',
  className,
  ...props
}: ComponentsDeliveredProps) {
  return (
    <div {...props} className={cn(className, styles.wrapper)}>
      <Htag tag="h5" variant={HtagVariant.h5} className={styles.title}>
        {title}
      </Htag>
      <ul className={cn(styles.list)}>
        {data &&
          data.map(({ Icon, text, id }) => (
            <li
              key={id}
              className={cn(styles.item, {
                [styles.two]: appearance === 'two',
                [styles.four]: appearance === 'four',
              })}
            >
              <Icon />
              {text}
            </li>
          ))}
      </ul>
    </div>
  );
}
