import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './container-fluid.module.css';

export interface ContainerFluidProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

export default function ContainerFluid({
  children,
  className,
  ...props
}: ContainerFluidProps) {
  return (
    <div {...props} className={cn(className, styles['container-fluid'])}>
      {children}
    </div>
  );
}
