import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './service-card-blog.module.css';

export interface ServiceCardBlogProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export default function ServiceCardBlog({
  title,
  children,
  className,
}: ServiceCardBlogProps) {
  return (
    <li className={cn(styles.card, className)}>
      <div className={styles.icon}>{children}</div>
      <div className={styles.title}>{title}</div>
    </li>
  );
}
