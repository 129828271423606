import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './banner.module.css';

export interface BannerProps {
  Start: ReactNode;
  End: ReactNode;
  className?: string;
}

export default function Banner({ Start, End, className }: BannerProps) {
  return (
    <div className={cn(styles.banner, className)}>
      <div className={styles.banner_start}>{Start}</div>
      <div className={styles.banner_end}>{End}</div>
    </div>
  );
}
