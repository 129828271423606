import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './paragraph.module.css';

export interface ParagraphProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  align?: 'left' | 'right' | 'center' | 'justify';
  children: ReactNode;
}

export default function Paragraph({
  align = 'left',
  children,
  className,
}: ParagraphProps) {
  return (
    <p className={cn(className, styles.paragraph, styles[align])}>{children}</p>
  );
}
