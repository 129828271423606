import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';
import styles from './search-button.module.css';
import Search from '../public/search.svg';

export interface SearchButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export default function SearchButton({
  className,
  ...props
}: SearchButtonProps) {
  return (
    <button
      type="button"
      aria-label="Search"
      className={cn(styles.searchButton, className)}
      {...props}
    >
      <Search />
    </button>
  );
}
