import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Image, { StaticImageData } from 'next/image';
import cn from 'classnames';
import styles from './card-description.module.css';
import { Htag, Paragraph, HtagAlign, HtagVariant } from '.';
import {} from './h-tag';

export interface CardDescriptionProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  src: string | StaticImageData;
  text?: string;
  title: string;
  id?: string;
  list?: string[];
  alt: string;
}

export default function CardDescription({
  className,
  title,
  text,
  src,
  list,
  alt,
  ...props
}: CardDescriptionProps) {
  return (
    <div className={cn(styles.card, className)} {...props}>
      <div className={styles.icon}>
        <Image alt={alt} src={src} width={200} height={200} />
      </div>
      <Htag
        tag="h4"
        variant={HtagVariant.h4}
        align={HtagAlign.center}
        className={styles.title}
      >
        {title}
      </Htag>
      {text && (
        <Paragraph align="center" className={styles.text}>
          {text}
        </Paragraph>
      )}
      {list && (
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <div className={styles.markup}>
                <Paragraph align="center" className={styles.text}>
                  {item}
                </Paragraph>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
