import Link from 'next/link';
import { Container } from '../../components';
import styles from './mobile-development.module.css';

export default function MobileDevelopment() {
  return (
    <section className={styles.development}>
      <Container>
        <h2 className={styles.development_title}>
          Full-cycle iOS and Android app development
        </h2>
        <p className={styles.development_text}>
          If you have been planning to create a mobile application for a long
          time or this topic is just interesting for you, we are happy to tell
          you about our services. We make applications from scratch on two
          platforms and are ready to vouch for them. Let us tell you more
          details.
        </p>
        <ul className={styles.development_list}>
          <li className={styles.development_item}>
            <div className={styles.development_itemImg}>
              <Link href="/ios" passHref prefetch={false}>
                <a>
                  <img src="/mobile-development/iOS.jpg" alt="" />
                </a>
              </Link>
            </div>
            <div className={styles.development_footer}>
              <Link href="/ios" passHref prefetch={false}>
                <a className={styles.development_itemName}>iOS</a>
              </Link>
              <div className={styles.development_itemContent}>
                Swift
                <br />
                Objective-C
              </div>
            </div>
          </li>

          <li className={styles.development_item}>
            <div className={styles.development_itemImg}>
              <Link href="/android" passHref prefetch={false}>
                <a>
                  <img src="/mobile-development/android.jpg" alt="" />
                </a>
              </Link>
            </div>
            <div className={styles.development_footer}>
              <Link href="/android" passHref prefetch={false}>
                <a>
                  <div className={styles.development_itemName}>Android</div>
                </a>
              </Link>

              <div className={styles.development_itemContent}>
                Java
                <br />
                Kotlin
              </div>
            </div>
          </li>
        </ul>
      </Container>
    </section>
  );
}
