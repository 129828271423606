import { DetailedHTMLProps, HTMLAttributes } from 'react';
import CardDescription, { CardDescriptionProps } from './card-description';
import styles from './list-card-description.module.css';

export interface ListCardDescriptionProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  cards: CardDescriptionProps[];
}

export default function ListCardDescription({
  cards,
  ...otherProps
}: ListCardDescriptionProps) {
  return (
    <ul {...otherProps} className={styles.list}>
      {cards &&
        cards.map(({ id, ...props }) => (
          <li key={id} className={styles.item}>
            <CardDescription {...props} />
          </li>
        ))}
    </ul>
  );
}
