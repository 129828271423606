import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './hover-line.module.css';

export interface HoverLineProps {
  appearance?:
    | 'white'
    | 'letslink'
    | 'fitkonnekt'
    | 'keto'
    | 'healthCase'
    | 'nft';
  children: ReactNode;
}

export default function HoverLine({ children, appearance }: HoverLineProps) {
  return (
    <span
      className={cn(styles.hoverline, {
        [styles.white]: appearance === 'white',
        [styles.letslink]: appearance === 'letslink',
        [styles.fitkonnekt]: appearance === 'fitkonnekt',
        [styles.keto]: appearance === 'keto',
        [styles.healthCase]: appearance === 'healthCase',
        [styles.nft]: appearance === 'healthCase',
      })}
    >
      {children}
    </span>
  );
}
