import { AppProps } from 'next/app';
import Head from 'next/head';
import '../styles/globals.css';
import { ModalProvider } from '../context';
import { Layout } from '../components';
import GoogleAnalytics from '../components/google-analytics';

export default function MyApp({ Component, pageProps, router }: AppProps) {
  const canonicalHref =
    router.pathname === '/blog/[slug]'
      ? `/blog/${router.query.slug}`
      : router.pathname;

  return (
    <>
      <Head>
        <link rel="canonical" href={`https://mavinx.com${canonicalHref}`} />
      </Head>
      <ModalProvider>
        <Layout>
          <Component {...pageProps} />
          {process.env.NODE_ENV === 'production' && <GoogleAnalytics />}
        </Layout>
      </ModalProvider>
    </>
  );
}
