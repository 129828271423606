import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import LinkedinIcon from '../public/svg/linkedin.svg';
import InstagramIcon from '../public/svg/instagram.svg';
import DribbbleIcon from '../public/svg/dribbble.svg';
import ClutchIcon from '../public/svg/clutch.svg';
import BehanceIcon from '../public/svg/behance.svg';

import styles from './social.module.css';

export enum SocialVariant {
  behance = 'behance',
  clutch = 'clutch',
  dribble = 'dribble',
  instagram = 'instagram',
  linkedin = 'linkedin',
}

export interface SocialProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  variant: SocialVariant;
}

export default function Social({ className, variant }: SocialProps) {
  switch (variant) {
    case SocialVariant.linkedin:
      return (
        <span className={cn(styles.linkedin, className)}>
          <a
            href="https://www.linkedin.com/company/mavinx/mycompany/"
            className={styles.link}
            target="_blank"
            aria-label="Linkedin"
            rel="noopener noreferrer"
          >
            <LinkedinIcon />
          </a>
        </span>
      );
    case SocialVariant.instagram:
      return (
        <span className={cn(styles.instagram, className)}>
          <a
            href="https://www.instagram.com/mavinx.team/"
            className={styles.link}
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </span>
      );
    case SocialVariant.dribble:
      return (
        <span className={cn(styles.dribble, className)}>
          <a
            href="https://dribbble.com/mavinx_design"
            className={styles.link}
            aria-label="Dribbble"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DribbbleIcon />
          </a>
        </span>
      );
    case SocialVariant.clutch:
      return (
        <span className={cn(styles.clutch, className)}>
          <a
            href="https://clutch.co/profile/mavinx#summary"
            className={styles.link}
            aria-label="Clutch"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ClutchIcon />
          </a>
        </span>
      );
    case SocialVariant.behance:
      return (
        <span className={cn(styles.behance, className)}>
          <a
            href="https://www.behance.net/mavinx_team"
            className={styles.link}
            target="_blank"
            aria-label="Behance"
            rel="noopener noreferrer"
          >
            <BehanceIcon />
          </a>
        </span>
      );
    default:
      return null;
  }
}
