import { ComponentProps, ElementType, ReactNode } from 'react';
import cn from 'classnames';
import styles from './h-tag.module.css';

export enum HtagVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

export enum HtagAlign {
  left = 'left',
  right = 'right',
  center = 'center',
  justify = 'justify',
}

export enum HtagColor {
  light = 'light',
  strong = 'strong',
}

type KetoHtagOwnProps<E extends ElementType = ElementType> = {
  variant: HtagVariant;
  align?: HtagAlign;
  color?: HtagColor;
  children: ReactNode;
  tag?: E;
};

type KetoHtagProps<E extends ElementType> = KetoHtagOwnProps<E> &
  Omit<ComponentProps<E>, keyof KetoHtagOwnProps>;

const defaultElement = 'h1';

export default function Htag<E extends ElementType = typeof defaultElement>({
  variant,
  children,
  align = HtagAlign.left,
  color = HtagColor.strong,
  className,
  tag,
}: KetoHtagProps<E>) {
  const TagName = tag || defaultElement;

  return (
    <TagName
      className={cn(className, styles[variant], styles[align], styles[color])}
    >
      {children}
    </TagName>
  );
}
