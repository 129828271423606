import { ReactNode, useRef } from 'react';
import { useModal } from '../hooks/useModal';
import { Modal } from '.';

export interface LayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const { isOpen } = useModal();
  const bodyRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <main ref={bodyRef}>
        {children}
      </main>
      {isOpen && <Modal />}
    </>
  );
}
