import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './service-card.module.css';

export interface ServiceCardProps {
  title: string;
  className?: string;
  svg?: string;
  active: boolean;
  clickHandle: () => void;
  children?: ReactNode;
}

export default function ServiceCard({
  title,
  className,
  svg,
  active,
  clickHandle,
  children,
}: ServiceCardProps) {
  return (
    <li
      aria-hidden="true"
      onClick={clickHandle}
      className={cn(styles.card, className, {
        [styles.active]: active,
      })}
    >
      <div
        className={styles.icon}
        dangerouslySetInnerHTML={{ __html: svg || '' }}
      />
      {children}
      <div className={styles.title}>{title}</div>
    </li>
  );
}
