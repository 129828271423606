import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Image, { StaticImageData } from 'next/image';
import cn from 'classnames';
import styles from './intro-section.module.css';
import { Htag, HtagVariant, Paragraph } from '.';

export interface IntroSectionProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  text: string;
  img: string | StaticImageData;
  reverse?: boolean;
}

export default function IntroSection({
  title,
  text,
  img,
  reverse = false,
}: IntroSectionProps) {
  return (
    <section className={cn(styles.section, { [styles.reverse]: reverse })}>
      <div className={styles.section__info}>
        <Htag
          tag="h3"
          className={styles.section__title}
          variant={HtagVariant.h3}
        >
          {title}
        </Htag>
        <Paragraph>{text}</Paragraph>
      </div>
      <div className={styles.section__image}>
        {reverse ? (
          <>
            <img
              className={cn(styles.section__img, styles['section__img--left'])}
              src="/svg/circles.svg"
              alt="circles"
            />
            <img
              className={cn(styles.section__img, styles['section__img--right'])}
              src="./svg/dotted.svg"
              alt="dotted"
            />
          </>
        ) : (
          <>
            <img
              className={cn(styles.section__img, styles['section__img--left'])}
              src="./svg/dotted.svg"
              alt="dotted"
            />
            <img
              className={cn(styles.section__img, styles['section__img--right'])}
              src="/svg/circles.svg"
              alt="circles"
            />
          </>
        )}

        <Image
          width={400}
          height={456}
          className={styles.section__preview}
          src={img}
          alt={title}
        />
      </div>
    </section>
  );
}
