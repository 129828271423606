import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './tabs-list.module.css';
import { Htag, HtagAlign, HtagVariant, Paragraph } from '.';

export interface ITabContent {
  timing?: {
    time: string;
    result: Array<{
      id: number;
      text: string;
    }>;
  };
  description?: string;
}
export interface ITabItem {
  id: number;
  Icon: React.FunctionComponent<any>;
  title: string;
  content: ITabContent;
}
export interface ITabListProps {
  tabs: Array<ITabItem>;
}

export default function TabsList({ tabs }: ITabListProps) {
  const [active, setActive] = useState<number>();

  useEffect(() => {
    setActive(tabs[0].id);
  }, [tabs]);

  const setContent = (id: number): void => {
    setActive(id);
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles['tab-list']}>
        {tabs &&
          tabs.map(({ Icon, ...tab }) => (
            <li
              onMouseEnter={() => setContent(tab.id)}
              key={tab.id}
              className={cn(styles['tab-item'], {
                [styles.active]: tab.id === active,
              })}
            >
              <div
                className={cn(styles['tab-icon'], {
                  [styles.active]: tab.id === active,
                })}
              >
                <Icon />
              </div>
              <Htag
                tag="h3"
                align={HtagAlign.center}
                className={styles['tab-title']}
                variant={HtagVariant.h5}
              >
                {tab.title}
              </Htag>
            </li>
          ))}
      </ul>
      <ul className={styles.content}>
        {tabs &&
          tabs.map((tab) => (
            <li
              key={tab.id}
              className={cn(styles['content-item'], {
                [styles.active]: tab.id === active,
              })}
            >
              {tab.content.timing && (
                <div className={styles['content-top']}>
                  <div className={styles['content-start']}>
                    <div className={styles['content-start__title']}>Time</div>
                    <Paragraph>{tab.content?.timing.time}</Paragraph>
                  </div>
                  <div className={styles['content-end']}>
                    <div className={styles['content-end__title']}>Result</div>
                    <ul className={styles['content-end__list']}>
                      {tab.content.timing.result.map((item) => (
                        <li
                          key={item.id}
                          className={styles['content-end__item']}
                        >
                          <Paragraph>{item.text}</Paragraph>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {tab.content.description && (
                <div className={styles['content-description']}>
                  <div className={styles['content-description__title']}>
                    Description
                  </div>
                  <Paragraph>{tab.content.description}</Paragraph>
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}
