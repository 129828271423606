import { createContext, ReactNode, useState, useCallback } from 'react';

export interface IModalContext {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  setContent: (reactNode: ReactNode | null) => void;
  content: ReactNode | null | undefined;
}

const initialValue: IModalContext = {
  isOpen: false,
  open: () => console.log('open'),
  close: () => console.log('close'),
  setContent: () => true,
  content: null,
};

interface ModalProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const ModalContext = createContext<IModalContext>(initialValue);

function ModalProvider({ children }: ModalProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContentState] = useState<ReactNode | null>(null);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const setContent = useCallback((reactNode: ReactNode | null) => {
    setContentState(reactNode);
  }, []);

  const value = { isOpen, open, close, content, setContent };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export default ModalProvider;
