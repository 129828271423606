import Image from 'next/image';
import Link from 'next/link';
import { IArticle } from '../pages/blog/blog.types';
import { rgbDataURL } from '../utils/rgbDataUrl';
import styles from './post-preview.module.css';

export default function PostPreview({ slug, cover, title }: IArticle) {
  const link = `/blog/${slug}`;
  return (
    <li className={styles.wrapper}>
      <Link href={link} prefetch={false} passHref>
        <a className={styles.image}>
          <Image
            width={740}
            height={415}
            placeholder="blur"
            blurDataURL={rgbDataURL()}
            src={cover}
            alt={title}
          />
        </a>
      </Link>
      <div className={styles.content}>
        <Link href={link} className={styles.link} passHref prefetch={false}>
          <a>{title}</a>
        </Link>
      </div>
    </li>
  );
}
