import { useRouter } from 'next/router';
import React, {
  ChangeEvent,
  useEffect,
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from 'react';
import cn from 'classnames';
import styles from './search-text-field.module.css';

import Close from '../public/close.svg';
import Search from '../public/search.svg';
import useDebounce from '../hooks/useDebounce';

export interface SearchTextFieldProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  handleClose: () => void;
}

export default function SearchTextField({
  className,
  handleClose,
  ...props
}: SearchTextFieldProps) {
  const router = useRouter();
  const [search, setSearch] = useState(router.query.search || '');
  const debouncedCallback = useDebounce(search, 700);

  const searchHandle = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  };

  useEffect(() => {
    const { pathname, query } = router;
    const { page, ...prevQuery } = query;

    if (debouncedCallback === '') {
      delete prevQuery.search;
    } else {
      prevQuery.search = debouncedCallback;
      delete prevQuery.page;
    }
    router.replace({ pathname, query: { ...prevQuery } });
  }, [debouncedCallback]);

  return (
    <div className={cn(styles.input, className)}>
      <Search className={styles.input_searchIcon} />
      <input
        type="text"
        placeholder="What are you looking for?"
        className={styles.input_custom}
        onChange={searchHandle}
        value={search}
        {...props}
      />
      <button
        type="button"
        aria-label="Close"
        onClick={handleClose}
        className={styles.input_closeIcon}
      >
        <Close />
      </button>
    </div>
  );
}
