import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './error.module.css';

export interface ErrorProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  align?: 'left' | 'right' | 'center' | 'justify';
  children: ReactNode;
}

export default function Error({ align = 'left', children }: ErrorProps) {
  return <span className={cn(styles.error, styles[align])}>{children}</span>;
}
