import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Link from 'next/link';
import LogoIcon from '../public/logo/logo.svg';
import LogoPolandIcon from '../public/logo/logo-poland.svg';
import WhiteLogoIcon from '../public/logo/white-logo.svg';
import TextLogoIcon from '../public/logo/text-logo.svg';
import LetslinkIcon from '../public/logo/letslink-logo.svg';
import LogoBlack from '../public/logo/logo-black.svg';
import LogoKeto from '../public/logo/logo-keto.svg';
import HealthCase from '../public/logo/logo-healthCase.svg';
import NFT from '../public/logo/nft.svg';

export type type =
  | 'primary'
  | 'white'
  | 'text'
  | 'letslink'
  | 'fitkonnekt'
  | 'keto'
  | 'healthCase'
  | 'nft';

export interface LogoProps
  extends DetailedHTMLProps<HTMLAttributes<SVGElement>, SVGElement> {
  appearance?: type;
}

export default function Logo({
  appearance = 'primary',
  className,
  ...props
}: LogoProps) {
  switch (appearance) {
    case 'primary':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              {/* <LogoIcon {...props} itemProp="logo" /> */}
              <LogoPolandIcon {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );

    case 'healthCase':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <HealthCase {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );

    case 'fitkonnekt':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <LogoBlack {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );

    case 'white':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <WhiteLogoIcon {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );

    case 'text':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <TextLogoIcon {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );
    case 'letslink':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <LetslinkIcon {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );
    case 'keto':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <LogoKeto {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );

    case 'nft':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <NFT {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );

    default:
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="Mavinx">
              <LogoIcon {...props} itemProp="logo" />
            </a>
          </Link>
        </div>
      );
  }
}
