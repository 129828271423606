import cn from 'classnames';
import {
  ForwardedRef,
  forwardRef,
  DetailedHTMLProps,
  HTMLAttributes,
} from 'react';
import styles from './text-field.module.css';

export interface TextFieldProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  message?: string;
  error?: boolean;
  helper: string;
}

function TextField(
  { error, message, helper, className, ...props }: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <div className={cn(className, styles.wrapper)}>
      <label className={styles.label}>
        <span className={styles.helper_text}>{helper}</span>
        <input ref={ref} className={styles.input} {...props} />
      </label>
      {error && <span className={styles.error}>{message}</span>}
    </div>
  );
}

export default forwardRef(TextField);
