import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './container.module.css';

export interface ContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  variant?:
    | 'container_small'
    | 'container_medium'
    | 'container'
    | 'container_fluid';
}

export default function Container({
  children,
  className,
  variant = 'container',
  ...props
}: ContainerProps) {
  return (
    <div {...props} className={cn(className, styles[variant], 'container')}>
      {children}
    </div>
  );
}
