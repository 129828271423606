import { useRef, useEffect } from 'react';
import SwiperCore, { Mousewheel, Scrollbar } from 'swiper';
import Image from 'next/image';
import styles from './swiper-scroll.module.css';

import slide1 from '../public/letslink/slider/slide1.png';
import slide2 from '../public/letslink/slider/slide2.png';
import slide3 from '../public/letslink/slider/slide3.png';
import slide4 from '../public/letslink/slider/slide4.png';
import slide5 from '../public/letslink/slider/slide5.png';
import slide6 from '../public/letslink/slider/slide6.png';
import slide7 from '../public/letslink/slider/slide7.png';

SwiperCore.use([Mousewheel, Scrollbar]);

export default function SwiperScroll() {
  const refWrapper = useRef<any>(null);
  const refList = useRef<any>(null);

  const scrollHandle = () => {
    const slides = refList.current.children;
    const sliderWidth =
      slides[0].getBoundingClientRect().width * slides.length +
      (slides.length - 1) * 36 +
      40;
    const winHeight = window.innerHeight;

    const heraSliderData = refWrapper.current.getBoundingClientRect();

    if (
      heraSliderData.bottom - winHeight <= 0 &&
      heraSliderData.bottom >= heraSliderData.height
    ) {
      refList.current.style.transform = `translateX(-${
        ((sliderWidth - heraSliderData.width) /
          (winHeight - heraSliderData.height)) *
        (winHeight - heraSliderData.height - heraSliderData.top)
      }px)`;
    }
  };

  useEffect(() => {
    if (refWrapper.current && refList.current) {
      window.addEventListener('scroll', scrollHandle);
    }
    return () => {
      window.removeEventListener('scroll', scrollHandle);
    };
  }, [refWrapper, refList]);

  return (
    <div ref={refWrapper} className={styles.wrapper}>
      <ul ref={refList} className={styles.slider}>
        <li className={styles.slide}>
          <Image
            src={slide1}
            alt="LinkApp login form"
            width={296}
            height={638}
          />
        </li>
        <li className={styles.slide}>
          <Image
            src={slide2}
            alt="LinkApp map image"
            width={296}
            height={638}
          />
        </li>
        <li className={styles.slide}>
          <Image
            alt="LinkApp profile image"
            src={slide3}
            width={296}
            height={638}
          />
        </li>
        <li className={styles.slide}>
          <Image
            alt="LinkApp group image"
            src={slide4}
            width={296}
            height={638}
          />
        </li>
        <li className={styles.slide}>
          <Image
            alt="LinkApp geolocation image"
            src={slide5}
            width={296}
            height={638}
          />
        </li>
        <li className={styles.slide}>
          <Image
            alt="LinkApp party image"
            src={slide6}
            width={296}
            height={638}
          />
        </li>
        <li className={styles.slide}>
          <Image
            alt="LinkApp chat image"
            src={slide7}
            width={296}
            height={638}
          />
        </li>
      </ul>
    </div>
  );
}
