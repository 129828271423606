import { forwardRef, ForwardedRef } from 'react';
import InputMask, { Props } from 'react-input-mask';
import cn from 'classnames';
import styles from './text-mask.module.css';

export interface IMask extends Props {
  error?: boolean;
  message?: string;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  mask: string;
  readonly?: boolean;
  ref?: any;
  maskChar?: string;
}

function TextMask(
  { label, error, message, className, disabled, ...props }: IMask,
  ref: ForwardedRef<InputMask>,
) {
  return (
    <div className={cn(styles.wrapper, className)}>
      {label && (
        <label htmlFor="mask" className={styles.helper_text}>
          {label}
        </label>
      )}
      <InputMask
        className={styles.input}
        ref={ref}
        disabled={disabled}
        maskChar=""
        id="mask"
        {...props}
      />
      {error && <span>{message}</span>}
    </div>
  );
}

export default forwardRef(TextMask);
