import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import styles from './modal.module.css';
import { useModal } from '../hooks';

export default function Modal() {
  const [isWindow, setIsWindow] = useState(false);
  const modalContext = useModal();
  const [modal, setModal] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let portal: HTMLDivElement | null = null;
      portal = document.createElement('div');
      portal.classList.add('portal');
      document.body.appendChild(portal);

      setModal(portal);
      setIsWindow(true);
      setTimeout(() => {
        setOpen(true);
      }, 100);
    }

    return () => {
      const portal = document.body.querySelector('.portal');
      if (portal) {
        document.body.removeChild(portal);
      }
    };
  }, []);

  const clickHandler = (event: React.SyntheticEvent<EventTarget>) => {
    if (event.target instanceof HTMLElement) {
      const { type } = event.target.dataset;
      if (type === 'denied') {
        setTimeout(() => {
          setOpen(false);
          setClose(true);
          setTimeout(() => {
            modalContext.close();
          }, 300);
        }, 300);
      }
    }
  };

  if (!isWindow && !modal) {
    return null;
  }

  return (
    modal &&
    createPortal(
      <div
        className={cn(styles.modal, {
          [styles.open]: open,
          [styles.close]: close,
        })}
      >
        <div
          className={cn(styles.overlay)}
          aria-hidden="true"
          data-type="denied"
          onClick={(ev) => clickHandler(ev)}
        >
          <div className={styles.window}>{modalContext.content}</div>
        </div>
      </div>,
      modal,
    )
  );
}
