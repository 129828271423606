import InputMask, { Props } from 'react-input-mask';
import { forwardRef, ForwardedRef } from 'react';
import cn from 'classnames';
import styles from './mask-text-field.module.css';
import { Error } from '.';

export interface IMask extends Props {
  error?: boolean;
  message?: string;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  mask: string;
  readonly?: boolean;
  ref?: any;
  maskChar?: string;
}

function MaskTextField(
  { label, error, message, className, disabled, ...props }: IMask,
  ref: ForwardedRef<InputMask>,
) {
  return (
    <label className={cn(styles.wrapper, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <InputMask
        className={cn(styles.input, {
          [styles.error]: !!error,
        })}
        ref={ref}
        disabled={disabled}
        {...props}
      />
      {error && <Error>{message}</Error>}
    </label>
  );
}

export default forwardRef(MaskTextField);
