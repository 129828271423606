import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './tags.module.css';

export interface TagProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant: 'tag__green' | 'tag__white' | 'tag__greenLight' | 'tag__gray';
  children: string;
}

export interface ListTagProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  tags: TagProps[];
  disabled?: boolean;
}

function Tag({ variant, children, ...props }: TagProps) {
  return (
    <div {...props} className={cn(styles.tag, styles[variant])}>
      {children}
    </div>
  );
}

export default function ListTag({
  tags,
  className,
  disabled,
  ...props
}: ListTagProps) {
  return (
    <ul
      className={cn(className, styles.tags, {
        [styles.disabled]: disabled,
      })}
      {...props}
    >
      {tags &&
        tags.map((tag, index) => (
          <li className={styles.tags_item} key={index}>
            <Tag {...tag} />
          </li>
        ))}
    </ul>
  );
}
