import { useState, DetailedHTMLProps, HTMLAttributes } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import cn from 'classnames';
import styles from './timeline.module.css';
import Coffee from '../public/coffee.svg';
import Container from './container';
import Htag, { HtagAlign, HtagVariant } from './h-tag';
import Paragraph from './paragraph';
import RunNumber from './run-number';
import Tags from './tags';

export interface TimelineProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {}

type tabs = 'ba' | 'design' | 'development' | 'qa';

interface Progress {
  main: number;
  second: number;
}

export default function Timeline({ className, ...props }: TimelineProps) {
  const [progress, setProgress] = useState<Progress>({
    main: 10,
    second: 7,
  });
  const [cup, setCup] = useState<number>(53);
  const [active, setActive] = useState<tabs>('ba');

  const handleActiveTab = (
    progressValue: Progress,
    cupValue: number,
    activeStatus: tabs,
  ) => {
    setProgress({ ...progressValue });
    setCup(cupValue);
    setActive(activeStatus);
  };

  const tabHandleBA = () => {
    handleActiveTab({ main: 10, second: 7 }, 53, 'ba');
  };
  const tabHandleDesign = () => {
    handleActiveTab({ main: 15, second: 20 }, 186, 'design');
  };
  const tabHandleDevelopment = () => {
    handleActiveTab({ main: 55, second: 80 }, 998, 'development');
  };
  const tabHandleQa = () => {
    handleActiveTab({ main: 20, second: 10 }, 78, 'qa');
  };

  return (
    <section className={cn(className, styles.timeline)} {...props}>
      <Container variant="container_small">
        <div className={styles.timeline_inner}>
          <AnimationOnScroll
            delay={0}
            animateOnce
            duration={1.2}
            animateIn="animate__fadeInDown"
          >
            <Htag
              tag="h2"
              className={styles.timeline_title}
              align={HtagAlign.center}
              variant={HtagVariant.h2}
            >
              project timeline
            </Htag>
          </AnimationOnScroll>
          <AnimationOnScroll
            offset={100}
            animateOnce
            duration={1.2}
            animateIn="animate__fadeInDown"
          >
            <Paragraph className={styles.timeline_text}>
              The development process in MAVINX is splitted into 4 stages.
              We&apos;re starting with the Business Analysis where we identify
              the requirements and convert it into the technical documentation
              and working prototype. The next stage is always about the Visual
              Component of the project where we&apos;re working on the UI design
              and Brand Identity. Then the project planning starts and
              Development team joins the process. The app is constantly tested
              by QA team. Finally, when the app is polished and approved by
              client, we&apos;re taking care of the project release to the App
              Store and Play Market.
            </Paragraph>
          </AnimationOnScroll>

          <AnimationOnScroll
            offset={0}
            animateOnce
            duration={1.2}
            animateIn="animate__fadeInDown"
          >
            <div className={styles.progress}>
              <div className={styles.progress_circle}>
                <svg
                  width="216"
                  height="216"
                  viewBox="0 0 216 216"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="107.848" cy="108.43" r="100" />
                  <circle cx="107.848" cy="108.43" r="79" />
                  <circle
                    style={{
                      strokeDashoffset: 630 - (630 * progress.main) / 100,
                    }}
                    className={styles.active}
                    cx="107.848"
                    cy="108.43"
                    r="100"
                  />
                  <circle
                    style={{
                      strokeDashoffset: 500 - (500 * progress.second) / 100,
                    }}
                    cx="107.848"
                    cy="108.43"
                    r="79"
                  />
                </svg>
                <div className={styles.marker}>
                  <div className={styles.marker_item}>Timeline</div>
                  <div className={cn(styles.marker_item, styles.cup)}>
                    Сups of coffee
                  </div>
                </div>
              </div>
              <div className={styles.progress_statistics}>
                <span className={styles.progress_percent}>
                  <span>
                    <RunNumber
                      startNumber={0}
                      end={progress.main}
                      duration={0.8}
                    />
                  </span>
                  %
                </span>
                <span className={styles.progress_coffee}>
                  <RunNumber startNumber={0} end={cup} duration={0.8} />
                  <Coffee />
                </span>
              </div>
            </div>
            <div className={styles.hidden}>
              <div className={styles.buttons}>
                <button
                  type="button"
                  onClick={tabHandleBA}
                  className={cn(styles.button, styles.ba, {
                    [styles.active]: active === 'ba',
                  })}
                >
                  BA
                </button>
                <button
                  type="button"
                  onClick={tabHandleDesign}
                  className={cn(styles.button, styles.visualDesign, {
                    [styles.active]: active === 'design',
                  })}
                >
                  Visual Design
                </button>
                <button
                  type="button"
                  onClick={tabHandleDevelopment}
                  className={cn(styles.button, styles.development, {
                    [styles.active]: active === 'development',
                  })}
                >
                  Development
                </button>
                <button
                  type="button"
                  onClick={tabHandleQa}
                  className={cn(styles.button, styles.qa, {
                    [styles.active]: active === 'qa',
                  })}
                >
                  QA / Delivery
                </button>
              </div>

              <div className={styles.tags}>
                <Tags
                  onClick={tabHandleBA}
                  disabled={active !== 'ba'}
                  className={cn(styles.tagsBa, styles.tags_item)}
                  tags={[
                    {
                      children: 'MindMap',
                      variant: 'tag__green',
                    },
                    {
                      children: 'User Stories',
                      variant: 'tag__green',
                    },
                    {
                      children: 'Wireframes',
                      variant: 'tag__green',
                    },
                  ]}
                />

                <Tags
                  onClick={tabHandleDesign}
                  disabled={active !== 'design'}
                  className={cn(styles.tagsDesign, styles.tags_item)}
                  tags={[
                    {
                      children: 'UI Design',
                      variant: 'tag__white',
                    },
                    {
                      children: 'Motion',
                      variant: 'tag__white',
                    },
                    {
                      children: 'Branding',
                      variant: 'tag__white',
                    },
                  ]}
                />

                <Tags
                  onClick={tabHandleDevelopment}
                  disabled={active !== 'development'}
                  className={cn(styles.tagsDevelopment, styles.tags_item)}
                  tags={[
                    {
                      children: 'iOS app',
                      variant: 'tag__greenLight',
                    },
                    {
                      children: 'Admin panel',
                      variant: 'tag__greenLight',
                    },
                    {
                      children: 'Android app',
                      variant: 'tag__greenLight',
                    },
                    {
                      children: 'API',
                      variant: 'tag__greenLight',
                    },
                    {
                      children: 'Backend',
                      variant: 'tag__greenLight',
                    },
                  ]}
                />

                <Tags
                  onClick={tabHandleQa}
                  disabled={active !== 'qa'}
                  className={cn(
                    styles.tagsQa,
                    styles.tags_item,
                    styles.tags__last,
                  )}
                  tags={[
                    {
                      children: 'Testing',
                      variant: 'tag__gray',
                    },
                    {
                      children: 'Support',
                      variant: 'tag__gray',
                    },
                    {
                      children: 'Release of the project',
                      variant: 'tag__gray',
                    },
                  ]}
                />
              </div>
            </div>
          </AnimationOnScroll>
        </div>
      </Container>
    </section>
  );
}
