import { DetailedHTMLProps, HTMLAttributes } from 'react';
import staticComments from '../utils/comments';
import { Slider, Comment } from '.';

export interface CommentsListProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export default function CommentsList({ className }: CommentsListProps) {
  return (
    <Slider className={className}>
      {staticComments.map((comment) => (
        <Comment key={comment._id} {...comment} />
      ))}
    </Slider>
  );
}
