import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './list.module.css';

export interface ListProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  Icon: FC;
  title: string;
  list: string[];
}

export default function List({
  Icon,
  list,
  title,
  className,
  ...props
}: ListProps) {
  return (
    <div className={cn(className, styles.wrapper)} {...props}>
      <div className={styles.list_head}>
        <div className={styles.head_icon}>
          <Icon />
        </div>
        <div className={styles.head_title}>{title}</div>
      </div>
      <ul className={styles.list}>
        {list &&
          list.map((item) => <li className={styles.list_item}>{item}</li>)}
      </ul>
    </div>
  );
}
