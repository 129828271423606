import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './form-contact.module.css';
import { useModal } from '../hooks';
import { subscribeSchema } from '../validation/submit.schema';
import { sendMessage } from '../services/contact.service';
import { Button, MaskTextField, Textarea, TextField } from '.';

const DynamicFeedback = dynamic(() => import('./feedback'), {
  ssr: true,
});

export interface IFormContact {
  email: string;
  name: string;
  phone?: string;
  message: string;
}

export default function FormContact() {
  const { open, setContent } = useModal();
  const [disabled, setDisabled] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormContact>({
    mode: 'onBlur',
    resolver: yupResolver(subscribeSchema),
  });

  const onSubmit = async (data: IFormContact) => {
    setDisabled(true);
    const response = await sendMessage(data);

    if (String(response.status).startsWith('2')) {
      setDisabled(false);
      setContent(<DynamicFeedback />);
      open();
      reset();
    }
  };

  return (
    <form
      className={cn(styles.form, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.form__top}>
        <TextField
          className={styles.email}
          label="Email"
          {...register('email')}
          type="email"
          error={!!errors?.email}
          message={errors?.email?.message}
        />
        <TextField
          className={styles.name}
          label="Name"
          {...register('name')}
          error={!!errors?.name}
          message={errors?.name?.message}
        />
      </div>
      <div className={styles.form__bottom}>
        <Textarea
          className={styles.body}
          {...register('message')}
          label="Message"
          error={!!errors?.message}
          message={errors?.message?.message}
        />
        <MaskTextField
          className={styles.phone}
          label="Phone number"
          {...register('phone')}
          mask="+9999999999999"
          error={!!errors?.phone}
          message={errors?.phone?.message}
        />
      </div>
      <span className="contact__form-button button-contained">
        <Button
          appearance="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Send
        </Button>
      </span>
    </form>
  );
}
