import Link from 'next/link';
import Image from 'next/image';
import styles from './works-card.module.css';
import Htag, { HtagVariant } from './h-tag';
import Paragraph from './paragraph';
import HoverLine from './hover-line';
import { rgbDataURL } from '../utils/rgbDataUrl';

export interface ITag {
  id: number;
  link: string | null;
  name: string;
}

export interface WorksCardProps {
  src: string;
  title: string;
  link: string | null;
  description: string;
  tags: ITag[];
}

export default function WorksCard({
  src,
  title,
  link,
  description,
  tags,
}: WorksCardProps) {
  return (
    <li className={styles.card}>
      <div className={styles.images}>
        {link ? (
          <Link href={link} passHref prefetch={false}>
            <a>
              <Image
                width={690}
                height={700}
                priority
                placeholder="blur"
                blurDataURL={rgbDataURL()}
                objectFit="cover"
                src={src}
                alt={title}
              />
            </a>
          </Link>
        ) : (
          <Image
            width={690}
            height={700}
            priority
            placeholder="blur"
            blurDataURL={rgbDataURL()}
            objectFit="cover"
            src={src}
            alt={title}
          />
        )}
      </div>
      <div className={styles.content}>
        {link ? (
          <Link href={link} passHref prefetch={false}>
            <a>
              <Htag tag="h2" variant={HtagVariant.h5}>
                {title}
              </Htag>
            </a>
          </Link>
        ) : (
          <Htag tag="h2" variant={HtagVariant.h5}>
            {title}
          </Htag>
        )}
        <div className={styles.text}>
          <Paragraph>{description}</Paragraph>
        </div>
        <ul className={styles.list}>
          {tags.map((tag, index) => (
            <li key={tag.id} className={styles.item}>
              {tag.link ? (
                <HoverLine>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tag}
                    href={tag.link}
                  >
                    {tag.name}
                    {index !== tags.length - 1 && ','}
                  </a>
                </HoverLine>
              ) : (
                <span className={styles.tag}>
                  {tag.name}
                  {index !== tags.length - 1 && ','}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}
