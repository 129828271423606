import { useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';

export default function RunNumber({ startNumber, end, delay, duration }: any) {
  const countUpRef = useRef(null);

  const { start } = useCountUp({
    ref: countUpRef,
    start: startNumber,
    end,
    delay,
    duration,
  });

  useEffect(() => {
    start();
  }, [start]);

  useEffect(() => {
    start();
  }, [startNumber, end, start]);

  return (
    <div>
      <div ref={countUpRef} />
    </div>
  );
}
