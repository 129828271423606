import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useRef,
  useState,
} from 'react';
import Slider, { Settings } from 'react-slick';
import cn from 'classnames';
import styles from './slider.module.css';
import ArrowLeft from '../public/slider-arrow-left.svg';
import ArrowRight from '../public/slider-arrow-right.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface SliderProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

export default function CustomSlider({ className, children }: SliderProps) {
  const sliderRef = useRef<any>();
  const [index, setIndex] = useState(1);

  const settings: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    beforeChange: (_, current) => setIndex(current),
  };
  return (
    <div className={cn(styles.slider, className)}>
      <Slider className={styles.wrapper} ref={sliderRef} {...settings}>
        {children}
      </Slider>
      <span
        aria-hidden="true"
        className={cn(styles.button, styles['button--left'])}
        onClick={() => {
          if (sliderRef) {
            sliderRef?.current?.slickPrev();
          }
        }}
      >
        <ArrowLeft />
      </span>

      <span
        aria-hidden="true"
        className={cn(styles.button, styles['button--right'])}
        onClick={() => {
          if (sliderRef) {
            sliderRef?.current?.slickNext();
          }
        }}
      >
        <ArrowRight />
      </span>
      {children && (
        <ul className={styles.dots}>
          {Array.isArray(children) &&
            children.map((_, i) => (
              <li
                key={i}
                aria-hidden="true"
                onClick={() => sliderRef.current.slickGoTo(i)}
                className={cn(styles.dot, {
                  [styles.dot__active]: i === index,
                })}
              />
            ))}
        </ul>
      )}
    </div>
  );
}
