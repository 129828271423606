import { ComponentProps, ElementType, ReactNode } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import styles from './button.module.css';

type ButtonOwnProps<E extends ElementType = ElementType> = {
  appearance:
    | 'primary'
    | 'ghost'
    | 'letslink'
    | 'fitkonnekt'
    | 'keto'
    | 'healthCase'
    | 'design'
    | 'nft';
  children: ReactNode;
  tag?: E;
};

type ButtonProps<E extends ElementType> = ButtonOwnProps<E> &
  Omit<ComponentProps<E>, keyof ButtonOwnProps>;

const defaultElement = 'button';

export default function Button<E extends ElementType = typeof defaultElement>({
  appearance,
  children,
  className,
  tag,
  href,
  ...props
}: ButtonProps<E>) {
  const TagName = tag || defaultElement;

  return (
    <span
      className={cn(styles.container, {
        [styles.primary]: appearance === 'primary',
        [styles.ghost]: appearance === 'ghost',
        [styles.letslink]: appearance === 'letslink',
        [styles.fitkonnekt]: appearance === 'fitkonnekt',
        [styles.healthCase]: appearance === 'healthCase',
        [styles.keto]: appearance === 'keto',
        [styles.design]: appearance === 'design',
        [styles.nft]: appearance === 'nft',
      })}
    >
      {TagName === 'a' ? (
        <Link href={href} prefetch={false}>
          <a className={cn(styles.button, className)} {...props}>
            {children}
          </a>
        </Link>
      ) : (
        <TagName className={cn(styles.button, className)} {...props}>
          {children}
        </TagName>
      )}
    </span>
  );
}
