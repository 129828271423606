import Link from 'next/link';
import React from 'react';
import styles from './mobile.module.css';
import Notification from './notification';
import Awards, { AwardsProps } from '../pages/templates/awards';
import Domains, { DomainsProps } from '../pages/templates/domains';
import Platform from '../pages/templates/platform';
import MobileDevelopment from '../pages/templates/mobile-development';
import { IArticles } from '../pages/blog/blog.types';
import WorksCard, { WorksCardProps } from './works-card';
import { Button, Container, PostPreviewList, WorksCardList } from '.';

export interface MobileProps {
  awards: AwardsProps;
  works: WorksCardProps[];
  domains: DomainsProps;
  posts: IArticles;
}

export default function Mobile({
  awards,
  works,
  posts,
  domains: { domains },
}: MobileProps) {
  return (
    <div className={styles.page}>
      <section className={styles.intro}>
        <Container>
          <div className={styles.intro_inner}>
            <div className={styles.intro_start}>
              <h1 className={styles.intro_title}>
                MOBILE DEVELOPMENT SERVICES
              </h1>
              <p className={styles.intro_text}>
                To create a native application for Android and iOS, our team
                uses platform-specific programming languages such as Swift,
                Objective-C, Java, Kotlin.
              </p>
              <div className={styles.intro_button}>
                <Link href="/contact" passHref prefetch={false}>
                  <Button
                    tag="a"
                    className={styles.intro_button}
                    appearance="primary"
                    href=""
                  >
                    Calculate now
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.intro_end}>
              <div>
                <img alt="android icon" src="/mobile/android.svg" />
              </div>
              <div>
                <img alt="iOS icon" src="/mobile/iOS.svg" />
              </div>
            </div>
          </div>
          <div className={styles.devided} />
        </Container>
      </section>
      <MobileDevelopment />
      <Platform />

      <Domains domains={domains} />
      <Container>
        <Notification
          title="How much will your app cost?"
          buttonName="Calculate now"
          href="/contact"
          className={styles.notification}
        />
      </Container>

      <Awards {...awards} />

      <div className={styles.works}>
        <h2 className={styles.works_title}>RELATED WORKS</h2>
        <Container>
          <WorksCardList className={styles.works_list}>
            {works.map((work, index) => (
              <WorksCard key={index} {...work} />
            ))}
          </WorksCardList>
          <div className={styles.works_btn}>
            <Button tag="a" appearance="primary" href="/works">
              See all projects
            </Button>
          </div>
        </Container>
      </div>

      <section className={styles.posts}>
        <Container>
          <PostPreviewList
            title="Mobile app development insights"
            posts={posts}
          />
          <div className={styles.works_all}>
            <Button tag="a" href="/blog?search=mobile" appearance="primary">
              See all
            </Button>
          </div>
        </Container>
      </section>
      <Container>
        <Notification
          title="HAVE A PROJECT IN MIND?"
          buttonName="Contact us"
          href="/contact"
          className={styles.notification}
        />
      </Container>
    </div>
  );
}
