import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Image, { StaticImageData } from 'next/image';
import styles from './comment.module.css';
import { Paragraph } from '.';
import { rgbDataURL } from '../utils/rgbDataUrl';

export interface CommentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  _id: number;
  img: string | StaticImageData;
  text: string;
  name: string;
  company: string;
}

export default function Comment({
  _id,
  img,
  text,
  name,
  company,
  ...props
}: CommentProps) {
  return (
    <div className={styles.wrapper} {...props}>
      <div className={styles.image}>
        <Image
          alt={name}
          placeholder="blur"
          blurDataURL={rgbDataURL()}
          src={img}
          width={92}
          height={92}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.text}>
          <Paragraph>{text}</Paragraph>
        </div>
        <div className={styles.name}>{name}</div>
        <span className={styles.company}>{company}</span>
      </div>
    </div>
  );
}
