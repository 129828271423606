import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import cn from 'classnames';
import styles from './pagination.module.css';
import ArrowLeft from '../public/arrow-left.svg';
import ArrowRight from '../public/arrow-right.svg';

export default function Pagination({
  className,
  ...props
}: ReactPaginateProps) {
  return (
    <div className={cn(styles.root, className)}>
      <ReactPaginate
        {...props}
        previousLabel={<ArrowLeft />}
        nextLabel={<ArrowRight />}
        breakClassName="breakClassName"
        breakLinkClassName="breakLinkClassName"
        containerClassName="containerClassName"
        pageClassName="pageClassName"
        pageLinkClassName="pageLinkClassName"
        activeClassName="activeClassName"
        activeLinkClassName="activeLinkClassName"
        previousClassName="previousClassName"
        nextClassName="nextClassName"
        previousLinkClassName="previousLinkClassName"
        nextLinkClassName="nextLinkClassName"
        disabledClassName="disabledClassName"
      />
    </div>
  );
}
