import SpecificationIcon from '../public/svg/specification.svg';
import DesignIcon from '../public/svg/design.svg';
import AppleIcon from '../public/svg/apple.svg';
import AndroidIcon from '../public/svg/android.svg';
import WebIcon from '../public/svg/web.svg';
import BrandIcon from '../public/svg/branding.svg';
import TestingIcon from '../public/svg/testing.svg';
import SupportIcon from '../public/svg/support.svg';

const services = [
  {
    id: 1,
    title: 'Specification',
    Icon: SpecificationIcon,
  },
  {
    id: 2,
    title: 'Design',
    Icon: DesignIcon,
  },
  {
    id: 3,
    title: 'iOS App',
    Icon: AppleIcon,
  },
  {
    id: 4,
    title: 'Android App',
    Icon: AndroidIcon,
  },
  {
    id: 5,
    title: 'Web Dev',
    Icon: WebIcon,
  },
  {
    id: 6,
    title: 'Branding',
    Icon: BrandIcon,
  },
  {
    id: 7,
    title: 'Testing',
    Icon: TestingIcon,
  },
  {
    id: 8,
    title: 'Support',
    Icon: SupportIcon,
  },
];

export default services;
