import { DetailedHTMLProps, HTMLAttributes, useRef, useEffect } from 'react';
import Image, { StaticImageData } from 'next/image';
import { useMediaQuery } from 'react-responsive';
import styles from './scrolling.module.css';

export interface ScrollingProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  images: string[] | StaticImageData[];
}

export default function Scrolling({ images }: ScrollingProps) {
  const refWrapper = useRef<any>(null);
  const refList = useRef<any>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const scrollHandle = () => {
    const slides = refList.current.children;
    const sliderWidth =
      slides[0].getBoundingClientRect().width * slides.length +
      (slides.length - 1) * 37.5 +
      80;
    const winHeight = window.innerHeight;

    const heraSliderData = refWrapper.current.getBoundingClientRect();

    if (
      heraSliderData.bottom - winHeight <= 0 &&
      heraSliderData.bottom >= heraSliderData.height
    ) {
      refList.current.style.transform = `translateX(-${
        ((sliderWidth - heraSliderData.width) /
          (winHeight - heraSliderData.height)) *
        (winHeight - heraSliderData.height - heraSliderData.top)
      }px)`;
    }
  };

  useEffect(() => {
    if (refWrapper.current && refList.current) {
      window.addEventListener('scroll', scrollHandle);
    }
    return () => {
      window.removeEventListener('scroll', scrollHandle);
    };
  }, [refWrapper, refList]);

  return (
    <div ref={refWrapper} className={styles.wrapper}>
      <ul ref={refList} className={styles.scrolling}>
        {images &&
          images.map((src, index) => (
            <li key={index} className={styles.item}>
              <Image
                alt="illustration"
                className={styles.image}
                src={src}
                quality={85}
                width={isMobile ? 133 : 350}
                height={isMobile ? 160 : 450}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}
