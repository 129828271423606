import Link from 'next/link';
import React from 'react';
import styles from './bread-crumbs.module.css';

export interface CrumbProps {
  id: number;
  name: string;
  path: string;
}
export interface CrumbsProps {
  crumbs: CrumbProps[];
}

export default function BreadCrumbs({ crumbs }: CrumbsProps) {
  const renderCrumb = (crumb: CrumbProps, index: number) => {
    if (index + 1 === crumbs.length) {
      return (
        <li key={crumb.id} className={styles.crumb}>
          {crumb.name}
        </li>
      );
    }

    return (
      <li key={crumb.id} className={styles.crumb}>
        <Link href={crumb.path} prefetch={false}>
          <a className={styles.link}>{crumb.name}</a>
        </Link>
        <span className={styles.split}>/</span>
      </li>
    );
  };

  return <ul className={styles.crumbs}>{crumbs.map(renderCrumb)}</ul>;
}
